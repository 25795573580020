body {
    margin: 0;
    font-family: Allerta;
    /* color: white !important; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: Allerta;
}

input[type="file"] {
    display: none;
}

::-webkit-scrollbar {
    display: none;
}

button.mapboxgl-popup-close-button {
    height: 30px !important;
    width: 30px !important;
    font-size: 24px !important;
    padding: 0 !important;
    line-height: 1 !important;
    border-top-right-radius: 8px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
    supported by Chrome and Opera */
}

.react-tel-input .form-control {
    font-family: "Allerta" !important;
    width: 244px !important;
    height: 37px !important;
    background-color: transparent !important;
    color: white !important;
    border: none !important;
    border-radius: 8px !important;
    margin: 10px !important;
    font-size: 14px !important;
    outline: none !important;
}

.react-tel-input .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    outline: none !important;
}

.react-tel-input .form-control::placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    border-radius: 8px 0 0 8px !important;
    background: transparent !important;
}

.react-tel-input .arrow {
    display: none !important;
}

.swiper {
    width: 90%;
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
}
  
.swiper-slide {
    text-align: center;
    font-size: 16px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}



.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.back-button {
    margin-left:'10px'
}

.next-button {
    margin-right: '10px'
}

@font-face {
    font-family: "Allerta";
    src: local('Allerta'),
        url('./fonts/Allerta/Allerta-Regular.ttf') format ('ttf')
}
